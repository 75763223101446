<template>
    <b-row>
      <b-col sm="4" offset="3">
      <b-form-group
        label-for="participation_type"
      >
        <template v-slot:label>
          {{ $t('approvedParticipation.participation_type') }}
        </template>
        <b-form-select
          plain
          id="is_attend"
          :options="attendList"
          v-model="search.is_attend"
        >
          <template v-slot:first>
            <b-form-select-option :value=2>{{$t('globalTrans.all')}}</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col sm="4">
      <b-button size="sm" variant="primary" class="mt-20" @click="getFinalList">
        <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
      </b-button>
    </b-col>
      <b-col lg="12" sm="12">
          <template>
            <b-overlay :show="loader">
              <div>
                <b-row v-if="isResult">
                  <b-col sm="12">
                    <b-table-simple small resonsive borderless>
                      <b-tr>
                          <b-th width="15%">{{ $t('approvedParticipation.participation_type') }}</b-th>
                          <b-th width="2%">:</b-th>
                          <b-td>
                            {{ getTypeOfFair() }}
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-th width="15%">{{ $t('globalTrans.fiscal_year') }}</b-th>
                          <b-th width="2%">:</b-th>
                          <b-td>
                            {{ $i18n.locale == 'en' ? detailsData.fiscal_year_en : detailsData.fiscal_year_bn }}
                          </b-td>
                      </b-tr>
                      <b-tr>
                          <b-th width="15%">{{ $t('globalTrans.fair_name') }}</b-th>
                          <b-th width="2%">:</b-th>
                          <b-td>
                            {{ $i18n.locale == 'en' ? detailsData.fair_name_en : detailsData.fair_name_bn }}
                          </b-td>
                      </b-tr>
                  </b-table-simple>
                    <b-table-simple bordered small>
                      <thead>
                        <tr class="text-align-center">
                          <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.sl_no') }}</th>
                          <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('externalTradeFair.participation_id') }}</th>
                          <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('externalTradeFair.comp_name') }}</th>
                          <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.address') }}</th>
                          <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('globalTrans.phone_no') + ' [' + $t('globalTrans.office') + ']' }}</th>
                          <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('externalTradeFair.type_of_comp') }}</th>
                          <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('externalTradeFair.establishment_year') }}</th>
                          <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                          <th style="padding: 0.5rem 0.7rem;font-size: 1.1rem;">{{ $t('approvedParticipation.attend') }}</th>
                        </tr>
                      </thead>
                      <b-tbody v-if="participants?.length > 0">
                        <b-tr v-for="(item, index) in Object.values(participants)" :key="index">
                          <b-td>{{ $n(index + 1) }}</b-td>
                          <b-td>{{ item.app_auto_id }}</b-td>
                          <b-td>{{ $i18n.locale == 'en' ? item.name_en : item.name_bn }}</b-td>
                          <b-td>{{ $i18n.locale == 'en' ? item.address_en : item.address_en }}</b-td>
                          <b-td>{{ item.phone_no | mobileNumber }}</b-td>
                          <b-td>{{ item.company_type !== 4 ? getCompanyName(item.company_type) : ($i18n.locale == 'en' ? item.other_comp_type_en : item.other_comp_type_bn) }}</b-td>
                          <b-td>{{$n(item.establishment_year, { useGrouping: false })}}</b-td>
                          <b-td>
                              <div v-for="(itm, indx) in item.stalls" :key="indx">
                                <span>{{ getStallCategory(itm.stall_detail_id) }} </span><span v-if="indx+1 < item.stalls.length">{{ ', ' }}</span>
                              </div>
                          </b-td>
                          <b-td>
                            <span v-if="item.is_attend === 0">{{ $i18n.locale == 'en' ? 'Absent' : 'অনুপস্থিত' }}</span>
                            <span v-if="item.is_attend === 1">{{ $i18n.locale == 'en' ? 'Present' : 'উপস্থিত' }}</span>
                          </b-td>
                        </b-tr>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </div>
            </b-overlay>
          </template>
      </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { getStallCatByFairApi, getAttendenceSortList } from '../../api/routes'
// import ListReportHead from '@/components/custom/ListReportHead.vue'
export default {
    name: 'Details',
    props: ['id', 'item'],
    components: {
      // ListReportHead
    },
    data () {
      return {
        loader: false,
        detailsData: {},
        stallCategoryList: [],
        search: {
          is_attend: 2
        },
        participants: [],
        isResult: false
      }
    },
    created () {
      if (this.item) {
        this.getFinalList()
        this.getStallCategoryList()
        const tmp = this.getParticipationInfoList()
        this.detailsData = tmp
      }
    },
    computed: {
      attendList () {
        return [
            { text: this.$i18n.locale === 'bn' ? 'অনুপস্থিত' : 'Absent', value: 0 },
            { text: this.$i18n.locale === 'bn' ? 'উপস্থিত' : 'Present', value: 1 }
        ]
      }
    },
    methods: {
      async getFinalList () {
        this.loader = true
        const params = Object.assign({}, { circular_id: this.item.id, fiscal_year_id: this.item.fiscal_year_id, is_attend: this.search.is_attend })
        let result = null
        result = await RestApi.getData(internationalTradeFairServiceBaseUrl, getAttendenceSortList, params)
        if (result.success) {
          this.participants = result.data
          this.loader = false
        }
        this.isResult = true
      },
      getTypeOfFair () {
        const typeObj = this.attendList.find(el => el.value === this.search.is_attend)
        if (typeObj) {
          return this.$i18n.locale === 'bn' ? typeObj.text : typeObj.text
        } else {
          return this.$i18n.locale === 'bn' ? 'সকল' : 'All'
        }
      },
      getStallCategory (id) {
        const dataStallCategory = this.stallCategoryList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return dataStallCategory !== undefined ? dataStallCategory.text_bn : ''
        } else {
          return dataStallCategory !== undefined ? dataStallCategory.text_en : ''
        }
      },
      getStallCategoryList () {
        this.loader = true
        RestApi.getData(internationalTradeFairServiceBaseUrl, getStallCatByFairApi, { fair_name_id: this.item.fair_name_id }).then(response => {
          this.loader = false
          if (response.success) {
            this.stallCategoryList = response.data.map(el => {
              return Object.assign({}, el, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
            })
          } else {
            this.stallCategoryList = []
          }
        })
      },
      getCompanyName (Id) {
          const Obj = this.$store.state.TradeFairService.commonObj.companyTypeList.find(el => el.value === parseInt(Id))
          if (typeof Obj !== 'undefined') {
            return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
          }
          return ''
      },
      getParticipationInfoList () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      }
    }
}
</script>
<style>
.download-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 1px 3px;
}

.yes-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 700;
  padding: 1px 3px;
}
</style>
