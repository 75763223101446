<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="4"
                label-for="fiscal_year_id">
                <template v-slot:label>
                  {{ $t('globalTrans.fiscal_year') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  id="fiscal_year_id"
                  :options="fiscalYearList"
                  v-model="formData.fiscal_year_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Fair name" vid="fair_name_id" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-cols-sm="4"
                label-for="fair_name_id">
                <template v-slot:label>
                  {{ $t('externalTradeFair.fair_name') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  id="fair_name_id"
                  :options="fairNameList"
                  v-model="formData.fair_name_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
          <b-table-simple bordered small>
            <b-thead>
              <b-tr>
                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                <b-th>{{ $t('externalTradeFair.participation_id') }}</b-th>
                <b-th>{{ $t('externalTradeFair.comp_name') }}</b-th>
                <b-th class="text-center">{{ $t('approvedParticipation.attend') }}</b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="fairInfo?.length">
              <b-tr v-for="(item, index) in fairInfo" :key="index">
                <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                <b-td>{{ item.app_auto_id }}</b-td>
                <b-td>{{ $i18n.locale == 'en' ? item.name_en : item.name_bn }}</b-td>
                <b-td class="text-center">
                  <b-form-checkbox
                      :id="'checkbox-2'+index"
                      v-model="item.is_attend"
                      :name="'checkbox-2'+index"
                      :value=1
                      :unchecked-value=0
                  >
                  </b-form-checkbox>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                  <td colspan="5" class="text-center">
                    <span class="text-black">{{ (currentLocale === 'bn') ? 'কোন তথ্য পাওয়া যায়নি!' : 'No Data Found' }}</span>
                  </td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        <b-row class="text-right">
          <b-col>
            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { getParticipantInfo, attendanceStore } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      internationalTradeFairServiceBaseUrl: internationalTradeFairServiceBaseUrl,
      valid: null,
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      fairInfo: [],
      formData: {
        fiscal_year_id: 0,
        fair_name_id: 0,
        attend: []
      },
      fairNameList: [],
      is_active: false
    }
  },
  created () {
    this.formData.fiscal_year_id = this.$store.state.currentFiscalYearId
    if (this.id) {
      const tmp = this.getFairPartiCircular()
      this.formData = tmp
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    countryList () {
      return this.$store.state.CommonService.commonObj.countryList.map(el => {
        return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
      })
    }
  },
  watch: {
    'formData.fiscal_year_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fairNameList = this.getFairNameList()
      }
    },
    'formData.fair_name_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loading = true
        this.getFairParticipantInfo(newVal)
      }
    }
  },
  mounted () {
    this.data = Object.assign({}, this.data, { fiscal_year_id: this.$store.state.currentFiscalYearId })
  },
  methods: {
    getFairNameList () {
      return this.$store.state.TradeFairService.commonObj.fairNameList.filter(item => item.fiscal_year_id === this.formData.fiscal_year_id).map(el => {
        return { value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en }
      })
    },
    async getFairParticipantInfo (fairId) {
      this.loading = true
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, `${getParticipantInfo}/${fairId}`)
      this.loading = false
      if (result.success) {
          this.fairInfo = result.data
      } else {
        this.fairInfo = {}
      }
    },
    async saveUpdate () {
      this.formData.attend = this.fairInfo
      const Ids = this.formData.attend.filter(el => el.is_attend === 1).map(item => {
        return (item.id)
      })
      if (!Ids.length) {
          return this.$toast.error({
              title: this.$t('globalTrans.error'),
              message: 'Add at least one item!',
              color: 'red'
          })
      }
      const obj = {
        selected_ids: Ids
      }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }

        result = await RestApi.postData(internationalTradeFairServiceBaseUrl, attendanceStore, obj)

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          // toaster message
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-form')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
    },
    getFairPartiCircular () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
