<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('approvedParticipation.fair_attendance_info') }} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col sm="4">
              <b-form-group
                label-for="fiscal_year_id"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.fiscal_year') }}
                </template>
                <b-form-select
                  plain
                  id="fiscal_year_id"
                  :options="fiscalYearList"
                  v-model="search.fiscal_year_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                  label-for="fair_name"
                >
                  <template v-slot:label>
                    {{ $t('externalTradeFair.fair_name') }}
                  </template>
                <b-form-select
                  plain
                  id="fair_name"
                  :options="fairNameList"
                  v-model="search.fair_name_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0 disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
          </b-row>
        </template>
        <!-- search section end -->
      </card>
      <body-card>
        <!-- table section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('approvedParticipation.fair_attendance_info') }} {{ $t('globalTrans.list') }}</h4>
        </template>
        <template v-slot:headerAction>
            <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
        </template>
        <template v-slot:body>
          <b-overlay :show="loadingState">
            <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
            <b-row>
              <b-col md="12" class="table-responsive">
                <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                  <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                    <template v-slot:cell(serial)="data">
                      {{ $n(data.item.serial + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-details variant="iq-bg-primary" size="sm" @click="detailsData(data.item)" class="action-btn edit" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>
                    </template>
                  </b-table>
                </slot>
                <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                  <b-pagination
                    v-model="pagination.currentPage"
                    :perPage="search.limit"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                  />
                 </div>
              </b-col>
            </b-row>
          </b-overlay>
        </template>
        <!-- table section end -->
      </body-card>
      <b-modal id="modal-details" size="xl" :title="$t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <template #modal-title class="flex-grow-1">
          {{ $t('approvedParticipation.fair_attendance_info') + ' ' + $t('globalTrans.details') }}
          <!-- <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
            {{  $t('globalTrans.export_pdf') }}
          </b-button> -->
        </template>
        <Details :id="detailsItemId" :item="itemData"  ref="details"/>
      </b-modal>
      <b-modal id="modal-form" size="xl" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Form :id="editItemId" :key="editItemId"/>
      </b-modal>
    </div>
</template>
  <script>
  import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
  import Details from './Details'
  import Form from './Form'
  import { fairAttendanceInfoList } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details, Form
    },
    data () {
      return {
        sortBy: '',
        detailsItemId: '',
        editItemId: '',
        itemData: [],
        search: {
          fiscal_year_id: 0,
          fair_name_id: 0,
          limit: 20
        },
        fairNameList: [],
        labelData: [
          { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '15%' } },
          { labels: 'globalTrans.fiscal_year', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
          { labels: 'externalTradeFair.fair_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '30%' } },
          { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 10 }
        ]
      }
    },
    created () {
      this.search.fiscal_year_id = this.$store.state.currentFiscalYearId
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      },
      'search.fiscal_year_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fairNameList = this.getFairNameList()
        }
      },
      currentLocale: function (newVal, oldVal) {
        if (this.search.fiscal_year_id) {
          this.fairNameList = this.getFairNameList()
        }
      }
    },
    computed: {
      listData: function () {
        const tmpData = this.$store.state.list
        return JSON.parse(JSON.stringify(tmpData))
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'fiscal_year_bn' },
            { key: 'fair_name_bn' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'fiscal_year_en' },
            { key: 'fair_name_en' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      formTitle () {
        return (this.editItemId === 0) ? this.$t('approvedParticipation.fair_attendance_info') + ' ' + this.$t('globalTrans.entry') : this.$t('approvedParticipation.fair_attendance_info') + ' ' + this.$t('globalTrans.modify')
      },
      fiscalYearList () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(el => el.status === 1)
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      getFairNameList () {
        return this.$store.state.TradeFairService.commonObj.fairNameList.filter(item => item.fiscal_year_id === this.search.fiscal_year_id).map(el => {
          return { value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en }
        })
      },
      detailsData (item) {
        this.detailsItemId = item.id
        this.itemData = item
      },
      editMethodInParent (item, type) {
        if (type === 1) {
          this.edit(item)
        }
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, fairAttendanceInfoList, params)
        if (result.success) {
          const listData = result.data.data.map((item, index) => {
            const fiscalYearData = this.$store.state.CommonService.commonObj.fiscalYearList.find(obj => obj.value === parseInt(item.fiscal_year_id))
            const tmpFair = this.$store.state.TradeFairService.commonObj.fairNameList.find(obj => obj.value === parseInt(item.fair_name_id))
            const customItem = {
              fiscal_year_en: fiscalYearData.text_en,
              fiscal_year_bn: fiscalYearData.text_bn,
              fair_name_en: tmpFair !== undefined ? tmpFair.text_en : '',
              fair_name_bn: tmpFair !== undefined ? tmpFair.text_bn : ''
            }
            return Object.assign({}, item, { serial: index }, customItem)
          })
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
  }
  </script>
  <style>
    .modal-title {
      flex-grow: 1 !important;
    }
  </style>
